(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/socket/assets/javascripts/socket.js') >= 0) return;  svs.modules.push('/components/lb-utils/socket/assets/javascripts/socket.js');
'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  socket,
  log
} = svs.core;
const logger = log.getLogger('lb-utils:socket');

const cidCounter = function () {
  let nextCid = 0;
  return () => nextCid++;
}();
class Socket extends React.Component {
  constructor() {
    var _this;
    super(...arguments);
    _this = this;
    _defineProperty(this, "state", {
      error: null
    });
    _defineProperty(this, "retryCount", 0);
    _defineProperty(this, "timeout", null);
    _defineProperty(this, "connected", false);
    _defineProperty(this, "cid", cidCounter());
    _defineProperty(this, "onSocketUpdate", function () {
      return _this.props.onUpdate(...arguments);
    });
    _defineProperty(this, "subscribe", () => {
      const {
        path,
        sendInitialUpdate,
        autoReconnect
      } = this.props;
      socket.subscribe(path, this.onSocketUpdate, this, sendInitialUpdate, autoReconnect);
      this.connected = true;
    });
    _defineProperty(this, "reconnect", error => {
      const {
        onTimeout,
        path,
        socketSubscribeRetryDelay,
        maxSocketSubscribeRetryCount
      } = this.props;
      if (error.path !== path) {
        return;
      }
      this.retryCount++;
      const logString = "Could not connect to socket, attempts: ".concat(this.retryCount, "/").concat(maxSocketSubscribeRetryCount, " code: ").concat(error.code, " msg: ").concat(error.message);
      socket.unsubscribe(path, this);
      if (this.retryCount < maxSocketSubscribeRetryCount) {
        this.timeout = setTimeout(this.subscribe, socketSubscribeRetryDelay * 1000);
        logger.info(logString);
      } else {
        this.connected = false;
        logger.warn(logString);
        onTimeout(error);
        this.setState({
          error: new Error('Could not establish connection to socket')
        });
      }
    });
    _defineProperty(this, "retryReconnect", () => {
      if (!this.connected) {
        this.startSubscription();
      }
    });
  }
  componentDidMount() {
    this.startSubscription(() => socket.on('cerror', this.reconnect, this));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path) {
      this.connected = false;
      socket.unsubscribe(prevProps.path, this);
      this.startSubscription();
    }
  }
  componentWillUnmount() {
    socket.unsubscribe(this.props.path, this);
    clearTimeout(this.timeout);
    socket.off('cerror', this.reconnect);
  }
  startSubscription(cb) {
    this.setState({
      error: null
    });
    this.retryCount = 0;
    clearTimeout(this.timeout);
    cb && cb();
    this.subscribe();
  }
  render() {
    const {
      children
    } = this.props;
    return typeof children === 'function' ? children({
      error: this.state.error,
      retryReconnect: this.retryReconnect
    }) : children;
  }
}
_defineProperty(Socket, "defaultProps", {
  maxSocketSubscribeRetryCount: 5,
  onTimeout: () => {},
  sendInitialUpdate: true,
  socketSubscribeRetryDelay: 5
});
setGlobal('svs.components.lbUtils.socket.Socket', Socket);

 })(window);