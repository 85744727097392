(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/socket/assets/javascripts/use-socket.js') >= 0) return;  svs.modules.push('/components/lb-utils/socket/assets/javascripts/use-socket.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }



const {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} = React;
const {
  socket,
  log
} = svs.core;
const logger = log.getLogger('lb-utils:use-socket');
const defaultOptions = {
  sendInitialUpdate: true,
  autoReconnect: true,
  socketSubscribeRetryDelay: 5000,
  maxSocketSubscribeRetryCount: 5,
  skip: false
};

const useSocket = (path, onSocketUpdate, opts) => {
  if (!path) {
    throw new Error('missing required path');
  }
  const options = _objectSpread(_objectSpread({}, defaultOptions), typeof onSocketUpdate === 'function' ? opts : onSocketUpdate);
  options.skip = Boolean(options.skip);
  const fakeSocketContext = useRef({
    current: {}
  });
  const [error, setError] = useState();
  const [retryCount, setRetryCount] = useState(0);
  const [lastMessage, setLastmessage] = useState();

  const socketCallbackInner = useRef();
  const socketCallback = useRef(function () {
    return socketCallbackInner.current(...arguments);
  });
  const reconnect = useCallback(() => {
    if (retryCount < options.maxSocketSubscribeRetryCount) {
      return;
    }
    setError(null);
    setRetryCount(0);
  }, [retryCount, options.maxSocketSubscribeRetryCount]);
  useEffect(() => {
    socketCallbackInner.current = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      logger.debug('socket update', path, args);
      if (typeof onSocketUpdate === 'function') {
        onSocketUpdate(...args);
      } else {
        setLastmessage(args[0]);
      }
    };
  }, [onSocketUpdate, path]);
  useEffect(() => {
    if (options.skip) {
      return;
    }
    let timerId;
    const socketCallbackRef = socketCallback.current;
    const reconnect = error => {
      logger.debug('socket error', error);
      if (retryCount >= options.maxSocketSubscribeRetryCount) {
        return setError(new Error("unable to connect to socket ".concat(path)));
      }
      timerId = setTimeout(() => setRetryCount(currentRetryCount => currentRetryCount + 1), options.socketSubscribeRetryDelay);
    };
    socket.subscribe(path, socketCallbackRef, fakeSocketContext.current, options.sendInitialUpdate, options.autoReconnect);
    logger.debug("socket connect: ".concat(path));
    socket.on('cerror', reconnect, fakeSocketContext.current);
    return () => {
      clearTimeout(timerId);
      socket.off('cerror', reconnect);
      logger.debug('socket disconnect', path);
      socket.unsubscribe(path, socketCallbackRef);
    };
  }, [path, retryCount, options.sendInitialUpdate, options.autoReconnect, options.maxSocketSubscribeRetryCount, options.socketSubscribeRetryDelay, options.skip]);
  return useMemo(() => ({
    lastMessage,
    error,
    reconnect
  }), [lastMessage, error, reconnect]);
};
setGlobal('svs.components.lbUtils.socket.useSocket', useSocket);

 })(window);